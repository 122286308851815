import { Component, Mixins } from "vue-property-decorator";

import CashFlowMixin from "../../common/mixins/cash-flow.mixin";

@Component
export default class MobileCashFlow extends Mixins(CashFlowMixin) {
  private get topBarSuccessButton() {
    return {
      title: this.$vuetify.lang.t("$vuetify.dashboard.search"),
      loading: this.loadingCashFlowList,
      disabled: this.disabledSearch,
      listeners: {
        click: this.refreshCashFlow,
      },
    };
  }

  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();

    this.$watch(
      () => {
        return this.rangeDate.join("-");
      },
      () => {
        this.refreshCashFlow();
      }
    );
  }
}
